<template>
  <ml-container>
    <ml-grid>
      <div class="tw-col-span-5">
        <div>
          <form ref="form" novalidate="false" @submit.prevent="onSubmit" @change="validateForm()" method="POST">

            <div class="tw-flex tw-items-center tw-justify-between">
              <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-4">
                {{ titleText }}

                {{ item.quote ? '/' . $t('vendor.offer.quote_request_creation_label') : null }}
              </h1>
              <router-link :to="{ name: 'vendor.offers' }" class="tw-text-red tw-text-sm tw-flex tw-items-center tw-mb-4">
                <svg class="tw-mr-3" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 1L1 5L5 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{$t('general.back')}}
              </router-link>
            </div>

            <div>

              <div v-if="quote" class="col">
                <v-alert
                    dense
                    color="orange"
                    icon="mdi-information-outline"
                    border="left"
                    elevation="2"
                    colored-border
                >
                  <v-row align="center">
                    <v-col class="grow orange--text">
                      {{quote.quote_description}}

                      <v-chip
                          small
                          v-for="(date,index) in this.quote.dates" :key=index
                          color="blue lighten-5"
                          label

                          class="ml-1"
                      >
                        {{date.datef}}
                      </v-chip>

                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                          v-if="1==2"
                          color="orange"
                          outlined
                          small
                          :to="{ name: 'vendor.quotes', query: { package: item.id }}"

                          class="routerLink"
                      >
                        Go to quote requests
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>

              </div>

              <div class="tw-mt-8">
                <ml-label required for="name">{{$t('vendor.offer.name')}}</ml-label>
                <div class="tw-mt-2">
                  <ml-input-text v-model="item.name" id="name" name="name" required/>
                  <ml-error-msg :msg="errors['name']" />
                </div>
              </div>
              <div class="tw-mt-6">
                <div class="tw-mt-2">
                    <div class="tw-text-sm tw-text-black tw-flex tw-items-center tw-space-x-3">
                      <span>{{ item.is_published ? $t('vendor.offer.published'):$t('vendor.offer.publish') }}</span>
                      <button :value="item.is_published" @click="item.is_published = !item.is_published" type="button"
                              :class="[item.is_published ? 'tw-bg-green-100' : 'tw-bg-red-100', 'tw-border-solid tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-5 tw-w-10 tw-border-2 tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none ']" role="switch" aria-checked="false">
                        
                        <span aria-hidden="true" :class="[item.is_published ? 'tw-translate-x-5 tw-bg-green' : 'tw-translate-x-0 tw-bg-red', 'tw-pointer-events-none tw-inline-block tw-h-4 tw-w-4 tw-rounded-full tw-shadow tw-transform tw-ring-0 tw-transition tw-ease-in-out tw-duration-200']"></span>
                      </button>
                    </div>

                </div>
              </div>

              <div class="tw-mt-6">
                <ml-label required for="description">{{$t('vendor.offer.description')}}</ml-label>
                <div class="tw-mt-2">
                  <editor
                      id="description" name="description"
                      v-model="item.description"
                      :init="{
                            
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                              'undo redo | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help'
                      }"
                  />                   
                  <ml-error-msg :msg="errors['description']" />
                </div>
              </div>

              <div class="tw-mt-6">
 
                  <ml-label for="package_discount">{{$t('vendor.offer.discount')}}</ml-label>
                  <div class="tw-mt-2">
                    <div class="tw-relative">
                      <div class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
                      <span class="tw-text-gray-m3 sm:tw-text-sm">
                        %
                      </span>
                      </div>
                      <ml-input-text class="tw-pl-7" type="number" min="0" max="100" v-model="item.package_discount" id="package_discount" name="package_discount" required/>
                    </div>
                    <ml-error-msg :msg="errors['package_discount']" />
                  </div>
        
              </div>

              <div class="tw-mt-6" v-if="products.length > 0">
               
                  <ml-label for="products">{{$t('vendor.product_type')}}</ml-label>
                  <div class="tw-mt-2">
                    <v-select
                        v-model="item.products"
                        :items="products"
                        item-text="name"
                        item-value="id"
                        multiple
                        
                        chips
                        dense
                    >

                      <template v-slot:selection="{ item }">
                        <v-chip color="white">
                          <!--
                              <span>{{ item.name }} | {{item.total_price_without_discount | currency}}</span>
                          -->
                              <span>{{ item.name }} | {{item.price | currency}}</span>
                            </v-chip>
                      </template>

                      <template v-slot:item="{item, attrs, on}">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <div class="col">
                                {{ item.name }} | {{ item.type.name }} 
                                </div>
                                <div class="col" align="right">
                                   <span class="tw-text-red">{{item.price | currency}}</span>
                                </div>
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
        
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggleProducts"
                        >
                          <v-list-item-action>
                            <v-icon :color="item.products.length > 0 ? 'grey darken-4' : ''">
                              {{ iconProduct }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select All
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>


                      <template v-slot:append-item>
                            <v-divider class="mb-2"></v-divider>
                          <v-list-item disabled>
                            <v-list-item-avatar color="grey lighten-3">
                              <v-icon>
                                mdi-basket-fill
                              </v-icon>
                            </v-list-item-avatar>

                        <v-list-item-content v-if="likesAllProducts">
                          <v-list-item-title>
                            All Services & Goods are selected to this Package
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-content v-else-if="likesSomePoducts">
                          <v-list-item-title>
                            Services & Goods Count
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.products.length }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-content v-else>
                          <v-list-item-title>
                            Please, select one of Services & Goods?
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Go ahead, make a selection above!
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      </template>      

                    </v-select>
                    <ml-error-msg :msg="errors['products']" />
                  </div>
                

              </div>

              <div class="tw-mt-6" v-if="fees.length > 0 & 1==2">
               
                  <ml-label for="products">{{$t('vendor.fees')}}</ml-label>
                  <div class="tw-mt-2">
                    <v-select
                        v-model="item.fees"
                        :items="fees"
                        item-text="name"
                        item-value="id"
                        multiple
                        
                        chips
                        dense
                    >
                    <template v-slot:selection="{ item }">
                      <v-chip color="white">
                            <span>{{ item.name }} | {{item.price | currency}}</span>
                          </v-chip>
                    </template>

                      <template v-slot:item="{item, attrs, on}">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <div class="col">
                                {{ item.name }} 
                                </div>
                                <div class="col" align="right">
                                   <span class="tw-text-red">{{item.price | currency}}</span>
                                </div>                                
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
        
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggleFees"
                        >
                          <v-list-item-action>
                            <v-icon :color="item.fees.length > 0 ? 'grey darken-4' : ''">
                              {{ iconFees }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select All
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>


                      <template v-slot:append-item>
                            <v-divider class="mb-2"></v-divider>
                          <v-list-item disabled>
                            <v-list-item-avatar color="grey lighten-3">
                              <v-icon>
                                mdi-basket-fill
                              </v-icon>
                            </v-list-item-avatar>

                        <v-list-item-content v-if="likesAllFees">
                          <v-list-item-title>
                            All Fees are selected to this Package
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-content v-else-if="likesSomeFees">
                          <v-list-item-title>
                            Fees Count
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.fees.length }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-content v-else>
                          <v-list-item-title>
                            Please, select one of Fees?
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Go ahead, make a selection above!
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      </template>  

                    </v-select>
                    <ml-error-msg :msg="errors['fees']" />
                  </div>
                

              </div>

              <div class="tw-mt-6">
               <strong> <span>Total: {{item.total_price_with_discount | currency}}</span></strong> |
               <span class="tw-text-xs tw-italic"> (calculated: {{total | currency}})</span>
              </div>
              
              <div class="tw-mt-6" v-if="addons.length && 1==2">
                <!--
                <div class="tw-col-span-4">
                  
                  <ml-label required for="tax_type">{{$t('vendor.offer.tax_type')}}</ml-label>
                  <div class="tw-mt-2">
                    <select v-model="item.tax_type" name="tax_type" id="tax_type" style="min-width: 180px;" class="tw-block tw-w-full tw-px-4 tw-py-3 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
                      <option :value="null">{{$t('vendor.offer.select_tax_type')}}</option>
                      <option :value="taxType.id" v-for="taxType in taxTypes" v-bind:key="taxType.id">{{ taxType.name }}</option>
                    </select>
                    <ml-error-msg :msg="errors['tax_type']" />
                  </div>
                  
                </div>
                -->
               
                  <ml-label for="addons">{{$t('vendor.offer.addons')}}</ml-label>
                  <div class="tw-mt-2">
                    <v-select
                        v-model="item.addons"
                        :items="addons"
                        item-text="name"
                        item-value="id"
                        multiple
                        
                        chips
                        dense
                    ></v-select>
                    <ml-error-msg :msg="errors['addons']" />
                  </div>
                

              </div>

              <div class="tw-mt-6" v-if="item.id">
                
                <ml-label>Photos</ml-label>
                <gallery v-if="item.images && item.images.length > 0"
                         class="tw-mt-3"
                         :images="item.images"
                         :gallery-setup="{}"
                         :default-image-class="'tw-h-28'"
                         :thumb-only="true"
                         :limit="item.images.length"
                         :thumb-wrapper-class="'tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-3'"
                         :show-border="false"
                >
                  <template v-slot:image-action="{image}">
                    <a href="#" @click.prevent="destroyImage(image.id)"
                       class="tw-right-2 tw-top-2 tw-h-6 tw-w-6 tw-bg-white tw-rounded-full tw-absolute tw-text-black tw-flex tw-items-center tw-justify-center">
                      <x-icon class="tw-h-3 tw-w-3" />
                    </a>
                  </template>
                </gallery>
              </div>
              <div class="tw-mt-6">
                  <div v-if="item.id">
                    <!-- Image -->

                      <v-file-input
                          @change="onFileSelected"
                          accept="image/png, image/jpeg, image/bmp"
                          :placeholder="$t('vendor.pick_image')"
                          prepend-icon="mdi-camera"
                          :label="$t('vendor.image_file_size')"
                          v-model="file"
                      ></v-file-input>

                      <v-progress-linear
                          v-show="imageUpload"
                          indeterminate
                          color="red"
                          class="mb-0"
                      ></v-progress-linear>

                      <v-alert
                        v-if="imageUploadError"
                        color="orange"
                        outlined
                        shaped
                        type="info"
                        >
                        <p>{{imageUploadError.message}}</p>

                        <p v-for="(e,index) in imageUploadError.errors.image" :key="index">
                          {{e}}
                        </p>

                      </v-alert>
                  </div>

             
              </div>
            </div>

            <div class="tw-mt-6">
              <router-link :to="{ name: 'vendor.offers' }"
                      class="tw-mt-6 tw-mr-6 tw-px-8 tw-py-3 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
                {{$t('general.cancel')}}
              </router-link>
              <button type="submit" :disabled="loading"
                      class="tw-mt-6 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-black focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black">
                {{ buttonText }}
              </button>
              <button type="submit" :disabled="loading"
              @click="item.is_published=true"
                  class="tw-mt-6 tw-ml-3 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-red focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black">
                {{ buttonText }} & {{$t('vendor.publish')}}
              </button>
            </div>
          </form>
        </div>
      </div>
    </ml-grid>
  </ml-container>
</template>

<script>

import MlContainer from "@/components/layout/MlContainer";
import MlGrid from "@/components/layout/MlGrid";
import MlLabel from "@/components/layout/form/MlLabel";
import MlInputText from "@/components/layout/form/MlInputText";
//import MlTextarea from "@/components/layout/form/MlTextarea";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
import Gallery from "@/components/Gallery";
import { XIcon } from 'vue-feather-icons';
import i18n from '@/i18n'

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import Editor from '@tinymce/tinymce-vue';

export default {
  components: {MlErrorMsg, MlGrid, MlContainer, MlLabel, MlInputText, Gallery, XIcon, Editor},
  middleware: ['auth', 'vendor', 'is-vendor-active'],
  metaInfo() {
    return {
      title: i18n.t('packagemng'),
    }
  },
  data() {
    return {
      taxes: [],
      taxTypes: [],
      categories: [],
      cat: [],
      addons: [],
      products: [],
      fees: [],
      quote: null,
      item: {
        is_published: false,
        quote: null,
        tax_inclusive: false,
        products: [],
        fees: [],
        price: 0
      },
      selectedFile: null,
      imageUploadError: null,
      imageUpload:false,        
      loading: false,
      file: null,
      rules: {
        required: value => !!value || 'Required.'
      },
      valid: true,
      lazy: true,
      errored: false,
      errors: [],
      eperrors: null,
      eperrorMsg: ""
    }
  },
  mounted: function () {
    //this.getTaxTypes();
    //this.getCategories();
    //this.getAddons();
    this.getProducts();
    this.getFees();

    if (this.$route.params.id) {
     this.getItem();
    }
    //this.getTaxes();
    if (this.$route.query.quote) {
      this.getQuote();
    }
  },
  watch:{
    eperrors: function(){
      //console.log("Object.values(object1)",Object.values(this.eperrors));
      Object.values(this.eperrors).forEach(element => {
        this.eperrorMsg = this.eperrorMsg + element[0];
      });
      //console.log("eperrorMsg",this.eperrorMsg);
      this.$toast.error(this.eperrorMsg, "Error", {timeout: 5000});
      this.eperrorMsg = "";
    },
    total: function(){
      if(this.total){
        this.item.price = this.total;
      }
    }
  },
  computed: {
      total(){
        if((this.products.length) && (this.item.products)){
          //var selectedProducts = this.products.filter(item => this.item.products.includes(item.id)).map(a => a.total_price_without_discount);
          let selectedProducts = this.products.filter(item => this.item.products.includes(item.id)).map(a => a.price);
          
         // var selectedFees = this.fees.filter(item => this.item.fees.includes(item.id)).map(a => a.price);


              let summed = 0.00;

                for (let key in selectedProducts) {
                    summed += Number(selectedProducts[key]);
                }
                /*
                for (let key1 in selectedFees) {
                    summed += Number(selectedFees[key1]);
                }
                */

                if(this.item.package_discount>0 && this.item.package_discount<=100){
                   summed = summed - ((summed / 100)*this.item.package_discount).toFixed(2);
                }

                //this.item.price = summed;

                return summed;

        }else{
          return 0;
        }


      },
      likesAllProducts () {
        return this.item.products.length === this.products.length
      },
      likesSomePoducts () {
        return this.item.products.length > 0 && !this.likesAllProducts
      },
      iconProduct () {
        if (this.likesAllProducts) return 'mdi-close-box'
        if (this.likesSomePoducts) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      likesAllFees () {
        return this.item.fees.length === this.fees.length
      },
      likesSomeFees () {
        return this.item.fees.length > 0 && !this.likesAllFees
      },
      iconFees () {
        if (this.likesAllFees) return 'mdi-close-box'
        if (this.likesSomeFees) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },      
    buttonText() {
      return this.item.id ? 'Save' : 'Create'
    },
    titleText() {
      return this.$route.params.id ? i18n.t('vendor.offer.edit_package') : i18n.t('vendor.offer.create_package')
    },
    dates() {
      if (this.quote.dates) {
        return this.quote.dates.map(day => day.date);
      } else {
        return null;
      }
    },
    attributes() {
      return this.dates.map(date => ({
        highlight: true,
        dates: date,
      }));
    },
  },
  methods: {
    updateTitle(name){
        console.log("name:",name)
        this.item.name = name.toString();
    },
    toggleProducts () {
        this.$nextTick(() => {
          if (this.likesAllProducts) {
            this.item.products = []
          } else {
            this.item.products = this.products.slice()
          }
          this.item.products = this.item.products.map(a => a.id);
        })
    },
    toggleFees () {
        this.$nextTick(() => {
          if (this.likesAllFees) {
            this.item.fees = []
          } else {
            this.item.fees = this.fees.slice()
          }
          this.item.fees = this.item.fees.map(a => a.id);
        })
    },         
    onDayClick(day) {
      console.log(day);
      return true;
      /*
      const idx = this.quote.dates.findIndex(d => d.id === day.id);
      if (idx >= 0) {
          this.quote.dates.splice(idx, 1);
      } else {
          this.quote.dates.push({
          id: day.id,
          date: day.date,
          });
      }
      */
    },
    required(value) {
      if (value instanceof Array && value.length === 0) {
        return 'Required.';
      }
      return !!value || 'Required.';
    },
    validateForm() {

      console.log("validate form...");
      this.errors = [];
      let label = '';

        if (!this.item.name) {
          label = "Name required.";
          this.errors.push(label);
          this.errors["name"] = label;
        }

        if (!this.item.description) {
          label = "Description required.";
          this.errors.push(label);
          this.errors["description"] = label;
        }
        /*
        if (!this.item.price) {
          label = "Price required.";
          this.errors.push(label);
          this.errors["price"] = label;
        }
        */

        if ((!this.between(this.item.package_discount,0,75) || !this.validatePercentage(this.item.package_discount))) {
          label = "Discount min 0 max 75.";
          this.errors.push(label);
          this.errors["package_discount"] = label;
        }

        if (this.total<10) {
          label = "The minimum amount is $10 (included discount)";
          this.errors.push(label);
          this.errors["package_discount"] = label;
        }


        /*
        if (!this.item.tax_type) {
          label = "Tax type required.";
          this.errors.push(label);
          this.errors["tax_type"] = label;
        }
        */
        if (this.item.products instanceof Array && this.item.products.length === 0 || !this.item.products) {
          label = "Service & Goods required.";
          this.errors.push(label);
          this.errors["products"] = label;
        } 
        /*
        if (this.item.categories instanceof Array && this.item.categories.length === 0 || !this.item.categories) {
          label = "Category required.";
          this.errors.push(label);
          this.errors["categories"] = label;
        }
        */       

      if (!this.errors.length) {
        this.valid = true;
        return true;
      }

      this.valid = false;
      return false;

    },
    validatePercentage(v){
      var re = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
      return re.test(v);
    },
    between(x, min, max) {
        return ((x-min)*(x-max) <= 0);
    },
    onSubmit(){
      if(this.validateForm()) this.dataEdit();
    },    
    async dataEdit() {
      if (this.item.id) {
        this.updateItem();
      } else {
        this.addItem();
      }
    },
    mapData() {
      //this.item.fees = this.item.fees.map(a => a.id);
      this.item.products = this.item.products.map(a => a.id);
      //this.item.tax = this.item.tax.id;
      //if (this.item.tax_type) this.item.tax_type = this.item.tax_type.id;
      //this.cat = this.item.categories.map(a => a.id);
      //this.item.categories = this.item.categories.map(a => a.id);
     // this.item.categories = this.item.categories[0].id;
      //this.item.addons = this.item.addons.map(a => a.id);
      //this.item.type_id = this.item.type.id;
    },
    async addItem() {
      //await this.$refs.form.validate();
      if(!this.validateForm()){
        return;
      }
      if (!this.valid) return;
      this.loading = true;
      this.item.type_id = 2;
      let uri = '/api/vendor/packages';
      await this.$http.post(uri, this.item)
          .then((response) => {
            this.item = response.data.data
            //this.mapData()
            this.$toast.success(response.data.message, "Success")
            this.$router.push({name: 'vendor.offers', query: { page: 'Package' }})
          }).catch(error => {
            //this.$toast.error(error.data.message, "Error");
            //console.log("error",error.data.errors);
            this.eperrors = error.data.errors;
            this.loading = false;
            this.errored = true;
          });
      this.loading = false;
    },
    async updateItem() {
      //await this.$refs.form.validate();
      if(!this.validateForm()){
        return;
      }
      if (!this.valid) return;
      this.loading = true;
      let uri = '/api/vendor/packages/' + this.item.id;
      await this.$http.patch(uri, this.item)
          .then((response) => {
            this.$toast.success(response.data.message, "Success");
            this.$router.push({name: 'vendor.offers', query: { page: 'Package' }})
            //this.$router.go(-1)
          }).catch(error => {
            //this.$toast.error(error.data.message, "Error");
            //console.log("error",error.data.errors);
            this.eperrors = error.data.errors;
            this.loading = false;
            this.errored = true;
          });
      this.loading = false;
    },
    async getItem() {
      this.loading = true;
      let uri = '/api/vendor/packages/' + this.$route.params.id + '/edit';
      this.$http.get(uri)
          .then((response) => {
            this.item = response.data.data
            this.quote = response.data.data.quote
          }).catch(error => {
        console.log('Error loading data: ' + error)
        this.errored = true
      }).finally(() =>
          this.mapData(),
          this.loading = false
      );

    },
    getTaxes() {
      let uri = '/api/vendor/taxes';
      this.$http.get(uri).then((response) => {
        this.taxes = response.data.data
      });
    },
    getTaxTypes() {
      let uri = '/api/vendor/tax/types';
      this.$http.get(uri).then((response) => {
        this.taxTypes = response.data.data
      });
    },
    getCategories() {
      let uri = '/api/vendor/categories?filter[tax_code_null]=false&filter[type_name_in]=Service,Goods';
      this.$http.get(uri).then((response) => {
        this.categories = response.data.data
      });
    },
    getAddons() {
      let uri = '/api/vendor/addons?filter[is_published]=1';
      this.$http.get(uri).then((response) => {
        this.addons = response.data.data
      });
    },
    getFees() {
      let uri = '/api/vendor/fees?filter[is_published]=1';
      this.$http.get(uri).then((response) => {
        this.fees = response.data.data
      });
    },
    getProducts() {
      let uri = '/api/vendor/products?filter[is_published]=1,0&filter[type]=Product,Add-on,Fees&filter[personalized]=0';
      this.$http.get(uri).then((response) => {
        this.products = response.data.data
      });
    },        
    getQuote() {
      let uri = '/api/vendor/quotes/' + this.$route.query.quote;
      this.$http.get(uri).then((response) => {
        this.quote = response.data.data;
        this.item.quote = this.quote.id;
      });
    },
    onFileSelected(event) {
      console.log(event);
      if (event == null) return;
      //this.selectedFile = event.target.files[0];
      this.selectedFile = event;
      this.onUpload();
    },
    onUpload() {
      this.imageUpload = true;
      this.imageUploadError = null;        
      const formData = new FormData();
      formData.append('image', this.selectedFile, this.selectedFile.name);
      let uri = '/api/vendor/packages/images/' + this.item.id;
      this.$http.post(uri, formData, {
        onUploadProgress: uploadEvent => {
          console.log('Upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100));
        }
      })
          .then((response) => {
            this.item.images = response.data.images;
            //this.item.images = this.item.images.concat(response.data.images);
            this.$toast.success(response.data.message, "Success")
            //this.$refs.inputFile.reset();
            this.file = null;
            this.imageUpload = false;
          }).catch((error) => {
            this.imageUpload = false;
            this.imageUploadError = error.data;
            console.log('Error: ', error.data);
          });
    },
    destroyImage(vId) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.onImageDelete(vId);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
    onImageDelete(id) {
      if (!this.item.id) return;
      let uri = '/api/vendor/packages/' + this.item.id + '/images/' + id;
      this.$http.delete(uri)
          .then((response) => {
            //this.item.images.push(response.data.images),
            this.item.images = response.data.images;
            //this.item.images = response.data.images;
            this.$toast.success(response.data.message, "Success", {timeout: 1000});
          });
    },
  }
}
</script>
